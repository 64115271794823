<template>
  <div>
    <div class="main_wrap">
      <div class="page_wrap" :style="{background: `url(${require('@/assets/v2/img/bg_room.jpg')}) no-repeat center`, backgroundSize: 'cover'}">
        <div class="con_wrap">
          <div class="login_message_box">
            <div class="login_message_area">
              <div class="title">
                <div class="text">인증정보가 필요합니다</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.opener && window.close();
  }
}
</script>

<style lang="scss" scoped>

.main_wrap {
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  .page_wrap {
    position: fixed;
    width: 100%;
    max-width: 800px;
    height: 100%;
    // background:url('/img/login/bg.png') no-repeat center;
    // background-size:cover;

    .con_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .login_message_box {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;

        .login_message_area {
          width: 300px;
          margin: 0 auto;

          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;

            .text {
              font-size: 23px;
              letter-spacing: -2px;
              background: -webkit-linear-gradient(#fff, #a8a7a7);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              filter: drop-shadow(1px 1px #000);
              opacity: 0.7;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

}

</style>